<template>
   <div class="cooperation-warp">
       <erji-carousel></erji-carousel>
       <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 院校合作模式 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                <p>
                    <!-- 全球互联网教育平台运营商，致力于构建终身教育生态系统 -->
                </p>
            </div>
       </div>
       <div class="yz-gov-con">
         <div class="gov-item">
            <img src="../../assets/images/school01.png">
            <p class="title-gov">合作办学</p>
            <hr class="fenge">
            <p class="desc-gov">依智教育提供从招生到就业的全程服务</p>
         </div>
         <div class="gov-item">
            <img src="../../assets/images/school02.png">
            <p class="title-gov">联合培养</p>
            <hr class="fenge">
            <p class="desc-gov">教师培训、专业课授课、实训基地建设、岗前实训、实习安排、就业指导、就业推荐、技能证书</p>
         </div>
         <div class="gov-item">
            <img src="../../assets/images/school03.png">
            <p class="title-gov">就业服务</p>
            <hr class="fenge">
            <p class="desc-gov">实训基地建设、就业指导课程、岗前实训、实习安排、 就业推荐、技能证书</p>
         </div>
         <div class="gov-item">
            <img src="../../assets/images/school04.png">
            <p class="title-gov">创业孵化</p>
            <hr class="fenge">
            <p class="desc-gov">创业指导课程、创业孵化</p>
         </div>
       </div>
      <div class="hezuo-con">
         <h2>合作企业</h2>
         <hr class="hezuo-fenge">
         <div class="hezuo-item">
            <img src="../../assets/images/school05.png">
         </div>
      </div>
   </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
.ab-text{
    margin:30px  0px;
    .ab-title{
       text-align: center;
       font-size: 30px;
       color:#0184d2;
    }
  .ab-desc{
    margin-top:10px;
    text-align: center;
    font-size: 14px;
    color: #656d78;
  }
}
   .cooperation-warp{
     margin-bottom: 40px;
     .yz-gov-con{
       width:1200px;
       margin:0 auto;
       display: flex;
        justify-content: center;/*水平居中*/
		    align-items: center;/*垂直居中*/
       .gov-item{
         width: 30%;
         height:400px;
         margin-right:20px;
         .title-gov{
           margin-top:20px;
           font-size:20px;
         }
         .desc-gov{
           font-size: 16px;
           line-height: 30px;
         }
         .fenge{
           width: 38px;
           display:inline-block;
           height: 3px;
           background: #148EFF;
           text-align: center;
           margin:20px 0px;
         }
       }
     }
     .hezuo-con {
        h2{
          font-size: 20px;
          font-weight: bold;
        }
       .hezuo-fenge{
           width: 38px;
           display:inline-block;
           height: 3px;
           background: #148EFF;
           text-align: center;
           margin:20px 0px;
       }
       .hezuo-item {
         width: 1200px;
         margin: 0 auto;
         height: 344px;
         background: #F7F7F7;

         img {
           width: auto;
           margin-top: 40px;
         }
       }
     }
   }
</style>
